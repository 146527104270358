import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

class ObsUrlModel extends Component {
  state = { urlCopied: false, keyCopied: false };
  render() {
    const {
      liveVideoData,
      loadingLiveVideo,
      ObsmodelButtonDisabled,
      startLiveAPI,
      ObsmodelLoadingContent,
    } = this.props;

    return (
      <div
        className="modal fade modal-index login-modal"
        id="obs_url_modal"
        role="dialog"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
              <h4 className="modal-title">OBS Broadcast Information</h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <p>
                    Note: To start the broadcast from the OBS, use the below
                    stream url and key. For more details or any questions?{" "}
                    <a
                      href="/obs-help"
                      target="_blank"
                      className="text-primary"
                    >
                      Click Here
                    </a>
                  </p>
                </div>
              </div>
              <div className="row">
                <form onSubmit={startLiveAPI}>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Stream URL</label>

                      <h4>
                        <code className="obs-code no-radius">
                          {loadingLiveVideo
                            ? ""
                            : liveVideoData.antmedia_obs_base_url}
                        </code>
                        <CopyToClipboard
                          onCopy={() => this.setState({ urlCopied: true })}
                          text={
                            loadingLiveVideo
                              ? ""
                              : liveVideoData.antmedia_obs_base_url
                          }
                        >
                          <button className="btn no-radius" type="button">
                            <i className="fa fa-clipboard"></i> Copy
                          </button>
                        </CopyToClipboard>
                      </h4>
                      {this.state.urlCopied ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null}

                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Streaming Url"
                        name="title"
                        value={
                          loadingLiveVideo
                            ? ""
                            : liveVideoData.antmedia_obs_hls_base
                        }
                        readOnly
                      /> */}
                    </div>

                    <div className="form-group">
                      <label>Stream Key</label>
                      <h4>
                        <code className="obs-code no-radius">
                          {loadingLiveVideo ? "" : liveVideoData.stream_key}
                        </code>
                        <CopyToClipboard
                          onCopy={() => this.setState({ keyCopied: true })}
                          text={
                            loadingLiveVideo ? "" : liveVideoData.stream_key
                          }
                        >
                          <button className="btn no-radius" type="button">
                            <i className="fa fa-clipboard"></i> Copy
                          </button>
                        </CopyToClipboard>
                      </h4>
                      {this.state.keyCopied ? (
                        <span style={{ color: "red" }}>Copied.</span>
                      ) : null}

                      {/* <input
                        type="text"
                        className="form-control"
                        placeholder="Streaming key"
                        name="title"
                        value={loadingLiveVideo ? "" : liveVideoData.stream_key}
                        readOnly
                      /> */}
                    </div>
                  </div>

                  <div className="modal-btn">
                    <button
                      type="submit"
                      className="btn"
                      disabled={ObsmodelButtonDisabled}
                      onClick={(event) => startLiveAPI(event, liveVideoData)}
                    >
                      {ObsmodelLoadingContent != null
                        ? ObsmodelLoadingContent
                        : "Start BroadCasting"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ObsUrlModel;
