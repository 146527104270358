import React, { Component } from "react";
import BroadcastChat from "./BroadcastChat";
import Sidebar from "../layouts/sidebar/Sidebar";
import api from "../../Environment";
import ToastContent from "../helper/ToastContent";
import { withToastManager } from "react-toast-notifications";
import io from "socket.io-client";
import config from "react-global-configuration";
import { translate, t } from "react-multi-lang";
import WebRTCAdaptor from "./webrtc_adaptor";

let chatSocket;

class LiveStreamingPlay extends Component {
  webRTCAdaptor = null;

  state = {
    subscribers: [],
    chatSocket: false,
    customizeData: {
      audioMute: true,
      audioUnmute: false,
      videoMute: true,
      videoUnmute: false,
      recordStatus: false,
    },
    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    groupData: null,
    loadingGroup: true,
    liveVideoData: null,
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,
    chatData: null,
    loadingChatData: true,
    chatInputMessage: "",
    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    streamingType: null,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
    mediaConstraints: {
      video: false,
      audio: false,
    },
    streamName: "",
    token: "",
    pc_config: {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    },
    sdpConstraints: {
      OfferToReceiveAudio: true,
      OfferToReceiveVideo: true,
    },
    websocketURL: "",
    isShow: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.webRTCAdaptor = this.initiateWebrtc();
    this.setState({
      isShow: true,
    });
    this.getSingleLiveVideoDetails();
  }

  onStartPlaying = () => {
    this.setState({ loadingStreamingPage: false });
    setTimeout(() => {
      this.webRTCAdaptor.play(
        this.state.streamName
          ? this.state.streamName
          : this.props.match.params.live_id,
        this.state.token
      );
    }, 5000);
  };

  initiateWebrtc = () => {
    let thiz = this;
    let websocketUrl =
      "wss://ant-media.startstreaming.co:5443/WebRTCAppEE/websocket";

    return new WebRTCAdaptor({
      websocket_url: websocketUrl,
      mediaConstraints: this.state.mediaConstraints,
      peerconnection_config: this.state.pc_config,
      sdp_constraints: this.state.sdpConstraints,
      remoteVideoId: "remoteVideo",
      isPlayMode: true,
      debug: true,
      candidateTypes: ["tcp", "udp"],
      callback: function (info, obj) {
        if (info == "initialized") {
          console.log("initialized");
        } else if (info == "play_started") {
          //joined the stream
          console.log("play started");
        } else if (info == "play_finished") {
          //leaved the stream
          console.log("play finished");
          ToastContent(
            thiz.props.toastManager,
            "Live streaming ended",
            "success"
          );
          window.location.replace("/");
        } else if (info == "closed") {
          //console.log("Connection closed");
          if (typeof obj != "undefined") {
            console.log("Connecton closed: " + JSON.stringify(obj));
          }

          ToastContent(
            thiz.props.toastManager,
            "Live streaming is not exist",
            "error"
          );

          setTimeout(() => {
            window.location.replace("/");
          }, 1000);
        } else if (info == "streamInformation") {
        } else if (info == "ice_connection_state_changed") {
          console.log("iceConnectionState Changed: ", JSON.stringify(obj));
        } else if (info == "updated_stats") {
          //obj is the PeerStats which has fields
          //averageIncomingBitrate - kbits/sec
          //currentIncomingBitrate - kbits/sec
          //packetsLost - total number of packet lost
          //fractionLost - fraction of packet lost
          console.log(
            "Average incoming kbits/sec: " +
              obj.averageIncomingBitrate +
              " Current incoming kbits/sec: " +
              obj.currentIncomingBitrate +
              " packetLost: " +
              obj.packetsLost +
              " fractionLost: " +
              obj.fractionLost +
              " audio level: " +
              obj.audioLevel
          );
        } else if (info == "data_received") {
          console.log(
            "Data received: " +
              obj.event.data +
              " type: " +
              obj.event.type +
              " for stream: " +
              obj.streamId
          );
        } else if (info == "bitrateMeasurement") {
          console.log(info + " notification received");

          console.log(obj);
        } else {
          console.log(info + " notification received");
        }
      },
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log("error callback: " + JSON.stringify(error));
        // alert(JSON.stringify(error));
        ToastContent(thiz.props.toastManager, t(error), "error");

        setTimeout(() => {
          window.location.replace("/");
        }, 1000);
      },
    });
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
        chatSocket.emit(
          "viewerCount",
          response.data.data.viewer_cnt ? response.data.data.viewer_cnt : 1
        );
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  getSingleLiveVideoDetails = () => {
    let LiveVideoID = this.props.match.params.live_id
      ? this.props.match.params.live_id
      : this.props.location.state.live_video_id;

    const inputData = {
      live_video_unique_id: LiveVideoID,
    };
    api.postMethod("live_videos_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          liveVideoData: response.data.data,
          loadingLiveVideo: false,
        });
        setTimeout(() => {
          if (this.state.liveVideoData.is_user_needs_to_pay == 1) {
            // this.props.history.push("/invoice", {
            //   state: this.state.liveVideoData,
            //   prevPath: location.pathname,
            // });
            this.props.history.push("/invoice", this.state.liveVideoData);
          } else {
            this.setState({
              streamName: response.data.data.stream_key,
              viewerCount: response.data.data.viewer_cnt,
            });
            // Watch streaming code needs to be add here.
            this.onStartPlaying();
            this.chatSocketConnect();
            this.getLiveVideoChatDetails();
            if (localStorage.getItem("userId") != response.data.data.user_id) {
              this.viewerUpdateAPI();
            }
          }
        }, 2000);
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        this.props.history.push("/");
      }
    });
  };

  getLiveVideoChatDetails = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };
    this.getLiveVideoChatAPI(inputData);
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: t("loading"),
    });
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };

    this.getLiveVideoChatAPI(inputData);
  };

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_chat", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.state.liveVideoData.virtual_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.state.liveVideoData.virtual_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
      chatSocket.on("viewerCount", (newData) => {
        console.log("On view count" + newData);
        this.setState({ viewerCount: newData });
      });
    }
  };

  handleChatSubmit = (event) => {
    event.preventDefault();
    const type =
      this.state.liveVideoData.user_id == localStorage.getItem("userId")
        ? "uv"
        : "vu";
    let chatData = [
      {
        live_video_id: this.state.liveVideoData.live_video_id,
        user_id: this.state.liveVideoData.user_id,
        live_video_viewer_id: localStorage.getItem("userId"),
        message: this.state.chatInputMessage,
        type: type,
        user_name: localStorage.getItem("username"),
        user_picture: localStorage.getItem("user_picture"),
      },
    ];
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket.emit("message", chatData[0]);
    }
    let messages;
    if (this.state.chatData != null) {
      messages = [...this.state.chatData, ...chatData];
    } else {
      messages = [...chatData];
    }
    this.setState({
      chatData: messages,
      chatInputMessage: "",
    });
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  stopBroadcastAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    if (this.state.apiCallstatus == false)
      api
        .postMethod("live_videos_broadcast_stop", inputData)
        .then((response) => {
          if (response.data.success) {
            ToastContent(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            if (
              this.state.liveVideoData.user_id == localStorage.getItem("userId")
            ) {
              window.location = "/live/history";
            } else {
              window.location = "/home";
            }
          } else {
            ToastContent(this.props.toastManager, response.data.error, "error");
          }
        });
  };

  render() {
    const { loadingContent, loadMoreButtonDisable, chatData, loadingChatData } =
      this.state;
    return (
      <div className="main">
        <Sidebar />

        {/* <video id="localVideo" autoPlay muted controls playsInline  ></video> */}
        {/* <VideoTag  callWebRTC={this.callWebRTC} /> */}
        {/* <video id="remoteVideo" autoPlay controls playsInline></video> */}

        {/* {this.state.loadingStreamingPage ? (
                    <div className="no-data-found-img">
                        <div className="Spacer-10"></div>
                        <img
                            src={
                                window.location.origin +
                                "/assets/img/fidget-spinner-loading.gif"
                            }
                        ></img>
                    </div>
                ) : ( */}
        <div className="sec-padding left-spacing1">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 top-margin">
              <div className="relative">
                <div>
                  <div className="col-md-12 bg-flora-white">
                    <div className="embed-responsive embed-responsive-16by9">
                      {/* <div className="relative"> */}
                      {/* Live streaming view code here */}
                      <video id="remoteVideo" autoPlay playsInline></video>
                      {/* </div> */}
                    </div>

                    <div className="Spacer-10"></div>

                    {this.state.liveVideoData == null ? (
                      ""
                    ) : (
                      <>
                        <div className="row broadcast-video">
                          <div className="col-md-6">
                            <button
                              className="btn1 margin-left-zero"
                              type="button"
                            >
                              <span className="text-uppercase">
                                {this.state.liveVideoData.type}
                              </span>
                            </button>
                            {this.state.liveVideoData.amount > 0 ? (
                              <button className="btn1" type="button">
                                <span>
                                  {this.state.liveVideoData.amount_formatted}
                                </span>
                              </button>
                            ) : (
                              ""
                            )}

                            <button className="btn1" type="button">
                              <i className="fas fa-eye icon"></i>
                              <span id="">
                                {this.state.viewerCount
                                  ? this.state.viewerCount
                                  : 0}
                              </span>
                            </button>
                          </div>

                          <div className="col-md-6">
                            {this.state.liveVideoData.user_id ==
                            localStorage.getItem("userId") ? (
                              <button
                                type="submit"
                                className="btn1"
                                onClick={() => this.stopBroadcastAPI()}
                              >
                                <i className="fas fa-stop-circle icon"></i>{" "}
                                {t("stop_broadcast")}
                              </button>
                            ) : (
                              <a href={"/"} className="btn1">
                                <i className="fa fa-sign-out icon"></i>{" "}
                                {t("exit")}
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="content-stm-video">
                          <div className="video-content">
                            <h1 className="h4-s text-bold top">
                              {this.state.liveVideoData.title}
                            </h1>

                            <div className="user-details">
                              <span className="user-img-sm">
                                <img
                                  className="img-circle img-responsive user-details-img"
                                  src={this.state.liveVideoData.user_picture}
                                ></img>
                              </span>
                              <span className="user-name-info">
                                {this.state.liveVideoData.user_name}
                              </span>
                            </div>

                            <p className="top">
                              {this.state.liveVideoData.description}
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <BroadcastChat
              loadMore={this.loadMore}
              chatData={chatData}
              loadingChatData={loadingChatData}
              loadingContent={loadingContent}
              loadMoreButtonDisable={loadMoreButtonDisable}
              chatInputMessage={this.state.chatInputMessage}
              chatInputChange={this.chatInputChange}
              handleChatSubmit={this.handleChatSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withToastManager(translate(LiveStreamingPlay));
