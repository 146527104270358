import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

class MySubscriptionPlanCard extends Component {
  state = {};
  render() {
    const { subscription, subscriptionStatus } = this.props;
    return (
      <div className="row space-myplans">
        <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5 relative mt-small resp-width-full">
          <div
            className="subscription-bg"
            style={{ backgroundImage: "url(img/pro-bg1.jpg)" }}
          >
            <div
              className="height-200 subscription-overlay"
              ng-reflect-klass="height-200"
              ng-reflect-ng-className="[object Object]"
            >
              <div className="text-center">
                <h3 className="sub-head mt-0">{subscription.title}</h3>
                <h2 className="sub-head text-bold">
                  {subscription.amount_formatted}/
                  <span className="small-text">{subscription.plan_text}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7 pl-0 resp-width-full">
          <div className="myplans-content">
            <div className="myplan-details">
              <div className="header-content">
                <h4 className="sub-desc-1">
                  <span className="myplan-head">{t("expires_on")}: </span>
                  {subscription.expiry_date}
                </h4>
                <h4 className="sub-desc">
                  <span className="myplan-head">{t("description")}: </span>
                  {subscription.description}
                </h4>
                {subscription.cancel_reason != "" ? (
                  <h4 className="sub-desc">
                    <span className="myplan-head">{t("cancel_reason")}: </span>
                    {subscription.cancel_reason}
                  </h4>
                ) : null}
              </div>
              <h4 className="sub-desc-1">
                {subscription.show_autorenewal_pause_btn == 0 ? (
                  <button
                    className="margin-md btn-subscribe-cancel"
                    onClick={(event) => subscriptionStatus(event, subscription)}
                  >
                    {t("disable_auto_renewal_subscription")}
                  </button>
                ) : null}
                {subscription.show_autorenewal_pause_btn == 1 ? (
                  <button
                    className="margin-md btn-subscribe-cancel"
                    onClick={(event) => subscriptionStatus(event, subscription)}
                  >
                    {t("enable_auto_renewal_subscription")}
                  </button>
                ) : null}
              </h4>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>{t("payment_id")}</td>
                      <td>{subscription.payment_id}</td>
                    </tr>
                    <tr>
                      <td>{t("payment_mode")}</td>
                      <td className="text-uppercase">
                        {subscription.payment_mode}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("is_coupon_applied")}</td>
                      <td>
                        {subscription.is_coupon_applied == 1 ? "Yes" : "No"}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("total_paid_amount")}</td>
                      <td>{subscription.amount_formatted}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(MySubscriptionPlanCard);
