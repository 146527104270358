import React, { Component } from "react";
import Sidebar from "../../layouts/sidebar/Sidebar";
import api from "../../../Environment";
import RevenueDashboardLoader from "../../Loader/RevenueDashboardLoader";
import { translate, t } from "react-multi-lang";
import ToastContent from "../../helper/ToastContent";
import { withToastManager } from "react-toast-notifications";

class RevenueIndex extends Component {
  state = {
    revenueData: null,
    loadingRevenueData: true,
  };
  componentDidMount() {
    this.getRevenueDetails();
  }
  getRevenueDetails = () => {
    api.postMethod("vod_videos_owner_dashboard").then((response) => {
      if (response.data.success) {
        this.setState({
          revenueData: response.data.data,
          loadingRevenueData: false,
        });
      } else {
        ToastContent(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };
  render() {
    const { loadingRevenueData, revenueData } = this.state;
    return (
      <div className="main">
        <Sidebar />
        {loadingRevenueData ? (
          <RevenueDashboardLoader />
        ) : (
          <div className="sec-padding vodrevenue left-spacing1">
            <div className="Spacer-15"></div>
            <div className="public-video-header">{t("revenue_dashboard")}</div>
            <div className="col-md-12 top-margin padding-bottom-md  text-right">
              <a href="/settings" className="btn btn-default btn-block btn-br">
                <i className="fa fa-arrow-left "></i>Back
              </a>
            </div>
            <div className="Spacer-10"></div>
            <div className="row small-padding">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 resp-width-full">
                <div className="display-inline video-details">
                  <div className="video-left">
                    <img
                      className="video-icons"
                      src={
                        window.location.origin + "/assets/img/video-camera.png"
                      }
                    />
                  </div>
                  <div className="video-right">
                    <p className="text-grey-clr mt-0 size-16">
                      {t("total_videos")}
                    </p>
                    <h5 className="m-0">
                      {t("total_vod_videos")} : {revenueData.total_videos}{" "}
                      {revenueData.total_videos > 1 ? t("videos") : t("video")}
                    </h5>
                    <h5 className="m-0">
                      {t("total_live_videos")} : {revenueData.total_live_videos}{" "}
                      {revenueData.total_live_videos > 1
                        ? t("videos")
                        : t("video")}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 resp-width-full">
                <div className="display-inline video-details">
                  <div className="video-left">
                    <img
                      className="video-icons"
                      src={
                        window.location.origin + "/assets/img/video-cash.png"
                      }
                    />
                  </div>
                  <div className="video-right">
                    <p className="text-grey-clr mt-0 size-16">
                      {t("today_revenue")}
                    </p>
                    <h5 className="m-0">
                      {t("today_vod_revenue")} :{" "}
                      {revenueData.today_revenue_formatted}
                    </h5>
                    <h5 className="m-0">
                      {t("today_live_revenue")} :{" "}
                      {revenueData.today_live_video_payments}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4 resp-width-full">
                <div className="display-inline video-details">
                  <div className="video-left">
                    <img
                      className="video-icons"
                      src={window.location.origin + "/assets/img/dollar.png"}
                    />
                  </div>
                  <div className="video-right">
                    <p className="text-grey-clr mt-0 size-16">
                      {t("total_revenue")}
                    </p>
                    <h5 className="m-0">
                      {t("total_vod_revenue")} :{" "}
                      {revenueData.total_revenue_formatted}
                    </h5>
                    <h5 className="m-0">
                      {t("total_live_revenue")} :{" "}
                      {revenueData.total_live_video_amount_formatted}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withToastManager(translate(RevenueIndex));
