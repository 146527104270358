import React from "react"
import ContentLoader from "react-content-loader"

const NotificationViewAllLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1050}
    height={800}
    viewBox="0 0 1200 920"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="450" y="40" rx="0" ry="0" width="319" height="37" /> 
    <rect x="0" y="700" rx="5" ry="5" width="1180" height="123" /> 
    <rect x="0" y="107" rx="5" ry="5" width="1180" height="74" /> 
    <rect x="0" y="205" rx="5" ry="5" width="1180" height="74" /> 
    <rect x="0" y="300" rx="5" ry="5" width="1180" height="74" /> 
    <rect x="0" y="396" rx="5" ry="5" width="1180" height="74" /> 
    <rect x="0" y="491" rx="5" ry="5" width="1180" height="74" /> 
    <rect x="0" y="587" rx="5" ry="5" width="1180" height="74" />
  </ContentLoader>
)

export default NotificationViewAllLoader;