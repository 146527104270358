import React from "react"
import ContentLoader from "react-content-loader"

const OtherGalleryLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1290}
    height={400}
    viewBox="0 0 1200 400"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="20" y="18" rx="5" ry="5" width="350" height="128" /> 
    <rect x="425" y="18" rx="5" ry="5" width="350" height="128" /> 
    <rect x="825" y="16" rx="5" ry="5" width="350" height="128" /> 
    <rect x="20" y="180" rx="5" ry="5" width="350" height="128" /> 
    <rect x="425" y="180" rx="5" ry="5" width="350" height="128" /> 
    <rect x="825" y="178" rx="5" ry="5" width="350" height="128" />
  </ContentLoader>
)

export default OtherGalleryLoader;