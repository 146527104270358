import React, { Component } from "react";
import { Link } from "react-router-dom";
import { translate, t } from "react-multi-lang";

class HomeSecCard extends Component {
  state = {};
  render() {
    const { video } = this.props;
    return (
      <div className="live-video-box">
        <div className="embed-responsive embed-responsive-16by9 ">
          <div className="inner-video">
            <div
              className="profile-bg-image embed-responsive-item"
              style={{
                backgroundImage: `url(${video.snapshot})`,
              }}
            ></div>
          </div>
          <Link
            to={{
              pathname:
                video.is_user_needs_to_pay == 1
                  ? "/invoice"
                  : `/livestreaming/${video.stream_key}`,
              state: video,
            }}
          >
            {/* <Link to={`/livestreaming/${video.virtual_id}`}> */}
            <div className="playbtn">
              <div className="white-btn-play">
                <img src="assets/img/play-btn.png" />
              </div>
              <div className="pink-btn-play">
                <img src="assets/img/play-btn-pink.png" />
              </div>
            </div>
          </Link>
        </div>
        {video.is_user_needs_to_pay == 1 ? (
          <div className="content-stm-video">
            <div className="ribbon-1">
              <h4 className="head">{video.amount_formatted}</h4>
            </div>
          </div>
        ) : null}
        <div className="content-stm-video">
          {/* <div className="video-right-btn">
            <button className="btn btn-default btn-block btn-br" type="button">
              Lorem Ipsum Lorem
            </button>
            <button className="btn btn-default btn-block btn-br" type="button">
              Music
            </button>
            <button className="btn btn-default btn-block btn-br" type="button">
              Music
            </button>
          </div> */}
          <div className="video-content">
            <h1 className="h4-s text-bold top">{video.title}</h1>
            <div className="row video-watch-time">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 watched-user">
                {t("watching")} - {video.viewer_cnt}
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 strated-time">
                {t("started")} - {video.created_at_formatted}
              </div>
            </div>
            <div className="user-details">
              <Link to={`/profile/${video.user_unique_id}`}>
                <span className="user-img-sm">
                  <img
                    className="img-circle img-responsive user-details-img"
                    src={video.user_picture}
                  />
                </span>
                <span className="user-name-info">{video.user_name}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(HomeSecCard);
