import React from "react"
import ContentLoader from "react-content-loader"

const FollowingLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1320}
    height={450}
    viewBox="0 0 1200 450"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="4" y="18" rx="5" ry="5" width="350" height="160" /> 
    <rect x="408" y="18" rx="5" ry="5" width="350" height="160" /> 
    <rect x="815" y="16" rx="5" ry="5" width="350" height="160" /> 
    <rect x="4" y="210" rx="5" ry="5" width="350" height="160" /> 
    <rect x="408" y="210" rx="5" ry="5" width="350" height="160" /> 
    <rect x="815" y="208" rx="5" ry="5" width="350" height="160" /> 
    <rect x="510" y="406" rx="20" ry="20" width="167" height="33" />
  </ContentLoader>
)

export default FollowingLoader;
