import React, { Component } from "react";
import BroadcastChat from "./BroadcastChat";
import Sidebar from "../layouts/sidebar/Sidebar";
import BroadcastPublicVideoSec from "./BroadcastPublicVideoSec";
import BroadcastStartModel from "./BroadcastStartModel";
import api from "../../Environment";
import ToastContent from "../helper/ToastContent";
import { withToastManager } from "react-toast-notifications";
import io from "socket.io-client";
import config from "react-global-configuration";
import { Redirect } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import WebRTCAdaptor from "./webrtc_adaptor";
import VideoMain from "./VideoMain";
import VideoTag from "./VideoTag";
import ObsUrlModel from "./ObsUrlModel";

const $ = window.$;
let chatSocket;

class LiveStreamingPublish extends Component {
  webRTCAdaptor = null;
  state = {
    subscribers: [],
    chatSocket: false,
    videoMute: true,
    videoUnmute: false,
    audioMute: true,
    audioUnmute: false,

    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    ObsmodelLoadingContent: null,
    ObsmodelButtonDisabled: false,
    ObsStreaming: false,
    groupData: null,
    loadingGroup: true,
    liveVideoData: null,
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,
    chatData: null,
    loadingChatData: true,
    chatInputMessage: "",
    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    streamingType: null,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
    normalStream: false,
    mediaConstraints: {
      video: true,
      audio: true,
    },
    streamName: "stream1",
    token: "",
    pc_config: {
      iceServers: [
        {
          urls: "stun:stun.l.google.com:19302",
        },
      ],
    },
    sdpConstraints: {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    },
    websocketURL: "wss://media.caribbealive.com:5443/WebRTCAppEE/websocket",
    isShow: false,
    viewLiveStreaming: false,
  };

  componentDidMount() {
    if (localStorage.getItem("isStreamer") != 1) {
      alert(t("live_stream_ended"));
      window.location.replace("/");
    }
    this.setState({ loadingStreamingPage: false });
    this.getGroupDetails();

    this.getLiveVideoSuggesstionDetails();
  }

  onStartPublishing = async () => {
    // this.saveWebRTC();
    this.webRTCAdaptor = await this.initiateWebrtc();

    if (document.getElementById("localVideo"))
      document.getElementById("localVideo").remove();

    const video = document.createElement("video");
    video.autoplay = true;
    video.playsInline = true;
    video.controls = true;
    video.muted = false;
    video.id = "localVideo";
    video.style.width = "100%";

    const videoBox = document.getElementById("video-box");

    videoBox.appendChild(video);

    let videox = document.querySelector("#localVideo");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          console.log("stream", stream);
          videox.srcObject = stream;
        })
        .catch(function (err0r) {
          console.log("Something went wrong!");
        });
    }

    setTimeout(() => {
      this.webRTCAdaptor.publish(
        this.state.liveVideoData.stream_key,
        this.state.token
      );

      this.setState({
        loadingStreamingPage: false,
        isShow: true,
      });
    }, 10000);
  };

  saveWebRTC = async () => {
    this.webRTCAdaptor = await this.initiateWebrtc();
    console.log("webRTC, ", this.webRTCAdaptor);
  };

  initiateWebrtc = () => {
    let thiz = this;
    return new WebRTCAdaptor({
      websocket_url: this.state.websocketURL,
      mediaConstraints: this.state.mediaConstraints,
      peerconnection_config: this.state.pc_config,
      sdp_constraints: this.state.sdpConstraints,
      localVideoId: "localVideo",
      debug: true,
      bandwidth: 900,
      callback: function (info, obj) {
        if (info == "initialized") {
          console.log("initialized", obj);
        } else if (info == "publish_started") {
          //stream is being published
          console.log("publish started");

          thiz.setState({
            isShow: false,
          });
        } else if (info == "publish_finished") {
          //stream is being finished
          console.log("publish finished");
          thiz.setState({
            isShow: true,
          });
          ToastContent(
            thiz.props.toastManager,
            "Live streaming stoppeed",
            "success"
          );
          window.location.replace("/");
        } else if (info == "closed") {
          //console.log("Connection closed");
          if (typeof obj != "undefined") {
            console.log("Connecton closed: " + JSON.stringify(obj));
          }
        } else if (info == "streamInformation") {
        } else if (info == "ice_connection_state_changed") {
          console.log("iceConnectionState Changed: ", JSON.stringify(obj));
        } else if (info == "updated_stats") {
          //obj is the PeerStats which has fields
          //averageIncomingBitrate - kbits/sec
          //currentIncomingBitrate - kbits/sec
          //packetsLost - total number of packet lost
          //fractionLost - fraction of packet lost
          console.log(
            "Average incoming kbits/sec: " +
              obj.averageIncomingBitrate +
              " Current incoming kbits/sec: " +
              obj.currentIncomingBitrate +
              " packetLost: " +
              obj.packetsLost +
              " fractionLost: " +
              obj.fractionLost +
              " audio level: " +
              obj.audioLevel
          );
        } else if (info == "data_received") {
          console.log(
            "Data received: " +
              obj.event.data +
              " type: " +
              obj.event.type +
              " for stream: " +
              obj.streamId
          );
        } else if (info == "bitrateMeasurement") {
          console.log(info + " notification received");

          console.log(obj);
        } else {
          console.log(info + " notification received");
        }
      },
      callbackError: function (error) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
        console.log("error callback: " + JSON.stringify(error));
        // alert(JSON.stringify(error));
      },
    });
  };

  modelChange = ({ currentTarget: input }) => {
    const startBroadcastInputData = {
      ...this.state.startBroadcastInputData,
    };
    startBroadcastInputData[input.name] = input.value;
    if (input.name == "payment_status" && input.value == 1) {
      $("#broadcast-amount").show();
    }
    if (input.name == "payment_status" && input.value == 0) {
      $("#broadcast-amount").hide();
    }
    this.setState({ startBroadcastInputData });
  };

  getGroupDetails = () => {
    api.postMethod("live_groups_index").then((response) => {
      if (response.data.success) {
        this.setState({
          groupData: response.data.data.groups,
          loadingGroup: false,
        });
      } else {
        ToastContent(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  startBroadcastAPI = (event) => {
    event.preventDefault();
    this.setState({
      modelLoadingContent: t("loading"),
      modelButtonDisabled: true,
      startBroadcastButtonDisable: true,
      startBroadcastButtonLoadingContent: t("loading"),
    });
    this.startBroadcastAPICall();
  };

  startBroadcastAPICall = () => {
    api
      .postMethod(
        "live_videos_broadcast_start",
        this.state.startBroadcastInputData
      )
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loadingLiveVideo: false,
            liveVideoData: response.data.data,
          });
          ToastContent(
            this.props.toastManager,
            response.data.message,
            "success"
          );

          console.log(response.data.data);

          if (response.data.data.livestream_type == "obs") {
            this.setState({
              ObsStreaming: true,
            });
            setTimeout(() => {
              $("#obs_url").click();
              $("#start_broadcast").modal("hide");
            }, 2000);
            return false;
          } else {
            // Start streaming code should be entered here.
            this.onStartPublishing();
            this.setState({
              normalStream: true,
            });
          }
          this.getLiveVideoChatDetails();
          this.chatSocketConnect();
        } else {
          if (response.data.error_code == 132) {
            ToastContent(this.props.toastManager, response.data.error, "error");
            // Send to subscription page.
          } else if (response.data.error_code == 174) {
            if (window.confirm(response.data.error)) {
              this.eraseOldStreamingAPI();
            } else {
              this.setState({
                modelLoadingContent: null,
                modelButtonDisabled: false,
                startBroadcastButtonDisable: false,
                startBroadcastButtonLoadingContent: null,
              });
              return false;
            }
          } else {
            ToastContent(this.props.toastManager, response.data.error, "error");
            this.setState({
              modelLoadingContent: null,
              modelButtonDisabled: false,
              startBroadcastButtonDisable: false,
              startBroadcastButtonLoadingContent: null,
            });
            return false;
          }
        }
        this.setState({
          modelLoadingContent: null,
          modelButtonDisabled: false,
        });
        $("#start_broadcast").modal("hide");
      });
  };

  snapShotAPI = () => {
    const inputData = {
      snapshot: this.state.snapshot,
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    api.postMethod("live_videos_snapshot_save", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          snapshot: null,
        });
      } else {
      }
    });
  };

  stopLiveStreaming = () => {
    console.log("stop");
    this.stopBroadcastAPI();
    this.webRTCAdaptor.stop(this.state.liveVideoData.stream_key);
  };

  stopBroadcastAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    if (this.state.apiCallstatus == false)
      api
        .postMethod("live_videos_broadcast_stop", inputData)
        .then((response) => {
          if (response.data.success) {
            ToastContent(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({ apiCallstatus: true });
          } else {
            ToastContent(this.props.toastManager, response.data.error, "error");
          }
        });
  };

  eraseOldStreamingAPI = () => {
    api.postMethod("live_videos_erase_old_streamings").then((response) => {
      if (response.data.success) {
        ToastContent(this.props.toastManager, response.data.message, "success");
        this.startBroadcastAPICall();
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  getLiveVideoSuggesstionDetails = () => {
    api.postMethod("live_videos_suggestions").then((response) => {
      if (response.data.success) {
        this.setState({
          liveVideoSuggesstion: response.data.data,
          loadingLiveVideoSuggesstion: false,
        });
      } else {
      }
    });
  };

  getSingleLiveVideoDetails = () => {
    const inputData = {
      live_video_id: this.props.location.state.live_video_id,
    };
    api.postMethod("live_videos_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          liveVideoData: response.data.data,
          loadingLiveVideo: false,
        });
        setTimeout(() => {
          if (this.state.liveVideoData.is_user_needs_to_pay == 1) {
            this.props.history.push("/invoice", this.state.liveVideoData);
          } else {
            // Watch streaming code needs to be add here.
            this.chatSocketConnect();
            this.getLiveVideoChatDetails();
          }
        }, 2000);
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        this.props.history.push("/");
      }
    });
  };

  getLiveVideoChatDetails = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };
    this.getLiveVideoChatAPI(inputData);
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: t("loading"),
    });
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };

    this.getLiveVideoChatAPI(inputData);
  };

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_chat", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.state.liveVideoData.virtual_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.state.liveVideoData.virtual_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
      chatSocket.on("viewerCount", (newData) => {
        console.log("On view count" + newData);
        this.setState({ viewerCount: newData });
      });
    }
  };

  handleChatSubmit = (event) => {
    event.preventDefault();
    if (this.state.chatInputMessage) {
      const type =
        this.state.liveVideoData.user_id == localStorage.getItem("userId")
          ? "uv"
          : "vu";
      let chatData = [
        {
          live_video_id: this.state.liveVideoData.live_video_id,
          user_id: this.state.liveVideoData.user_id,
          live_video_viewer_id: localStorage.getItem("userId"),
          message: this.state.chatInputMessage,
          type: type,
          user_name: localStorage.getItem("username"),
          user_picture: localStorage.getItem("user_picture"),
        },
      ];
      let chatSocketUrl = config.get("configData.chat_socket_url");
      console.log("chatSocketUrl" + chatSocketUrl);
      if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
        chatSocket.emit("message", chatData[0]);
      }
      let messages;
      if (this.state.chatData != null) {
        messages = [...this.state.chatData, ...chatData];
      } else {
        messages = [...chatData];
      }
      this.setState({
        chatData: messages,
        chatInputMessage: "",
      });
    }
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  startLiveAPI = (event, live_video_Data) => {
    event.preventDefault();
    console.log("live_video_Data" + live_video_Data);
    this.setState({
      ObsmodelLoadingContent: null,
      ObsmodelButtonDisabled: false,
      startBroadcastButtonDisable: true,
      startBroadcastButtonLoadingContent: "Loading...",
    });
    // if (live_video_Data.livestream_type == "obs") {
    window.location = "/livehost/" + live_video_Data.stream_key;
    // } else {
    //   this.startLiveAPICall(live_video_Data);
    // }
  };

  render() {
    const {
      startBroadcastInputData,
      modelLoadingContent,
      modelButtonDisabled,
      loadingGroup,
      groupData,
      ObsmodelLoadingContent,
      ObsmodelButtonDisabled,
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    return (
      <div className="main">
        <Sidebar />
        {/* <VideoTag  callWebRTC={this.callWebRTC} /> */}
        <video
          id="localVideo"
          autoPlay
          muted
          controls
          playsInline
          style={{ visibility: "hidden", height: "1px" }}
        ></video>
        {this.state.loadingStreamingPage ? (
          <div className="no-data-found-img">
            <div className="Spacer-10"></div>
            <img
              src={
                window.location.origin +
                "/assets/img/fidget-spinner-loading.gif"
              }
            ></img>
          </div>
        ) : (
          <div className="sec-padding left-spacing1">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 top-margin">
                {this.state.loadingLiveVideo == false ? (
                  this.state.liveVideoData == null ? (
                    ""
                  ) : this.state.normalStream == true ? (
                    <>
                      <div className="relative">
                        <div
                          id="video-box"
                          // style={{ width: "100% !important" }}
                        ></div>
                        {/* <VideoTag saveWebRTC={this.saveWebRTC} /> */}
                        {/* <video
                          id="localVideo"
                          autoPlay
                          muted
                          controls
                          playsInline
                        ></video> */}

                        <div style={{ display: "none" }}>
                          <button
                            className="btn"
                            type="button"
                            onClick={this.stopLiveStreaming}
                          >
                            {" "}
                            Stop Streaming{" "}
                          </button>
                          <button
                            className="btn"
                            type="button"
                            onClick={() =>
                              this.webRTCAdaptor.turnOffLocalCamera()
                            }
                          >
                            {" "}
                            Off camera{" "}
                          </button>
                          <button
                            className="btn"
                            type="button"
                            onClick={() =>
                              this.webRTCAdaptor.turnOnLocalCamera()
                            }
                          >
                            {" "}
                            on camera{" "}
                          </button>
                          <button
                            className="btn"
                            type="button"
                            onClick={() => this.webRTCAdaptor.muteLocalMic()}
                          >
                            {" "}
                            Mute
                          </button>
                          <button
                            className="btn"
                            type="button"
                            onClick={() => this.webRTCAdaptor.unmuteLocalMic()}
                          >
                            {" "}
                            Unmute
                          </button>
                        </div>
                      </div>
                      <div className="Spacer-10"></div>

                      <div className="row broadcast-video">
                        <div className="col-md-6">
                          <button
                            className="btn1 margin-left-zero"
                            type="button"
                          >
                            <span className="text-uppercase">
                              {this.state.liveVideoData.type}
                            </span>
                          </button>
                          {this.state.liveVideoData.amount > 0 ? (
                            <button className="btn1" type="button">
                              <span>
                                {this.state.liveVideoData.amount_formatted}
                              </span>
                            </button>
                          ) : (
                            ""
                          )}

                          <button className="btn1" type="button">
                            <i className="fas fa-eye icon"></i>
                            <span id="">
                              {this.state.viewerCount
                                ? this.state.viewerCount
                                : 0}
                            </span>
                          </button>
                        </div>

                        <div className="col-md-6">
                          {this.state.liveVideoData.user_id ==
                            localStorage.getItem("userId") &&
                          this.state.audioMute ? (
                            <button
                              type="submit"
                              className="btn1"
                              onClick={() => {
                                this.setState({
                                  audioMute: false,
                                  audioUnmute: true,
                                });
                                this.webRTCAdaptor.muteLocalMic();
                              }}
                            >
                              <i className="fas fa-microphone icon"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {this.state.liveVideoData.user_id ==
                            localStorage.getItem("userId") &&
                          this.state.audioUnmute ? (
                            <button
                              type="submit"
                              className="btn1"
                              onClick={() => {
                                this.setState({
                                  audioMute: true,
                                  audioUnmute: false,
                                });
                                this.webRTCAdaptor.muteLocalMic();
                              }}
                            >
                              <i className="fas fa-microphone-slash icon"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {this.state.liveVideoData.user_id ==
                            localStorage.getItem("userId") &&
                          this.state.videoMute ? (
                            <button
                              type="submit"
                              className="btn1"
                              onClick={() => {
                                this.setState({
                                  videoMute: false,
                                  videoUnmute: true,
                                });
                                this.webRTCAdaptor.turnOffLocalCamera();
                              }}
                            >
                              <i className="fas fa-video icon"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {this.state.liveVideoData.user_id ==
                            localStorage.getItem("userId") &&
                          this.state.videoUnmute ? (
                            <button
                              type="submit"
                              className="btn1"
                              onClick={() => {
                                this.setState({
                                  videoMute: true,
                                  videoUnmute: false,
                                });
                                this.webRTCAdaptor.turnOnLocalCamera();
                              }}
                            >
                              <i className="fas fa-video-slash icon"></i>
                            </button>
                          ) : (
                            ""
                          )}
                          {this.state.liveVideoData.user_id ==
                          localStorage.getItem("userId") ? (
                            <button
                              type="submit"
                              className="btn1"
                              onClick={this.stopLiveStreaming}
                            >
                              <i className="fas fa-stop-circle icon"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="content-stm-video">
                        <div className="video-content">
                          <h1 className="h4-s text-bold top">
                            {this.state.liveVideoData.title}
                          </h1>

                          <div className="user-details">
                            <span className="user-img-sm">
                              <img
                                className="img-circle img-responsive user-details-img"
                                src={this.state.liveVideoData.user_picture}
                              ></img>
                            </span>
                            <span className="user-name-info">
                              {this.state.liveVideoData.user_name}
                            </span>
                          </div>

                          <p className="top">
                            {this.state.liveVideoData.description}
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  <div>
                    <div className="relative">
                      <img
                        src={window.location.origin + "/assets/img/stream.jpg"}
                        className="img-responsive broadcast-img"
                      />

                      <div className="broadcast-btn">
                        <button
                          className="btn"
                          type="button"
                          // onClick={this.startStreaming}
                          data-toggle="modal"
                          data-target="#start_broadcast"
                          disabled={this.state.startBroadcastButtonDisable}
                        >
                          {this.state.startBroadcastButtonLoadingContent != null
                            ? this.state.startBroadcastButtonLoadingContent
                            : t("start_broadcasting")}
                        </button>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.ObsStreaming == true ? (
                  <button
                    className="btn none"
                    type="button"
                    data-toggle="modal"
                    data-target="#obs_url_modal"
                    id="obs_url"
                  >
                    URL STREAMING
                  </button>
                ) : (
                  ""
                )}
              </div>
              <BroadcastChat
                loadMore={this.loadMore}
                chatData={chatData}
                loadingChatData={loadingChatData}
                loadingContent={loadingContent}
                loadMoreButtonDisable={loadMoreButtonDisable}
                chatInputMessage={this.state.chatInputMessage}
                chatInputChange={this.chatInputChange}
                handleChatSubmit={this.handleChatSubmit}
              />
            </div>
          </div>
        )}

        <BroadcastStartModel
          startBroadcastInputData={startBroadcastInputData}
          modelLoadingContent={modelLoadingContent}
          modelButtonDisabled={modelButtonDisabled}
          groupData={groupData}
          loadingGroup={loadingGroup}
          modelChange={this.modelChange}
          startBroadcastAPI={this.startBroadcastAPI}
        />
        <ObsUrlModel
          liveVideoData={this.state.liveVideoData}
          loadingLiveVideo={this.state.loadingLiveVideo}
          ObsmodelButtonDisabled={ObsmodelButtonDisabled}
          ObsmodelLoadingContent={ObsmodelLoadingContent}
          startLiveAPI={this.startLiveAPI}
        />
      </div>
    );
  }
}

export default withToastManager(translate(LiveStreamingPublish));
