import React from "react"
import ContentLoader from "react-content-loader"

const PublicVideoLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1245}
    height={500}
    viewBox="0 0 1220 500"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="6" y="16" rx="0" ry="0" width="250" height="136" /> 
    <rect x="6" y="151" rx="0" ry="0" width="8" height="82" /> 
    <rect x="8" y="222" rx="0" ry="0" width="248" height="11" /> 
    <rect x="249" y="150" rx="0" ry="0" width="7" height="82" /> 
    <rect x="27" y="162" rx="0" ry="0" width="71" height="10" /> 
    <rect x="27" y="183" rx="0" ry="0" width="70" height="11" /> 
    <rect x="270" y="16" rx="0" ry="0" width="250" height="136" /> 
    <rect x="270" y="149" rx="0" ry="0" width="8" height="82" /> 
    <rect x="270" y="220" rx="0" ry="0" width="250" height="11" /> 
    <rect x="513" y="148" rx="0" ry="0" width="7" height="82" /> 
    <rect x="290" y="160" rx="0" ry="0" width="71" height="10" /> 
    <rect x="290" y="181" rx="0" ry="0" width="70" height="11" /> 
    <rect x="530" y="16" rx="0" ry="0" width="250" height="136" /> 
    <rect x="530" y="148" rx="0" ry="0" width="8" height="82" /> 
    <rect x="530" y="219" rx="0" ry="0" width="250" height="11" /> 
    <rect x="773" y="147" rx="0" ry="0" width="7" height="82" /> 
    <rect x="550" y="159" rx="0" ry="0" width="71" height="10" /> 
    <rect x="550" y="180" rx="0" ry="0" width="70" height="11" /> 
    <rect x="6" y="261" rx="0" ry="0" width="250" height="136" /> 
    <rect x="6" y="396" rx="0" ry="0" width="8" height="82" /> 
    <rect x="8" y="467" rx="0" ry="0" width="248" height="11" /> 
    <rect x="249" y="395" rx="0" ry="0" width="7" height="82" /> 
    <rect x="27" y="407" rx="0" ry="0" width="71" height="10" /> 
    <rect x="27" y="428" rx="0" ry="0" width="70" height="11" /> 
    <rect x="270" y="259" rx="0" ry="0" width="250" height="136" /> 
    <rect x="270" y="394" rx="0" ry="0" width="8" height="82" /> 
    <rect x="270" y="465" rx="0" ry="0" width="250" height="11" /> 
    <rect x="513" y="393" rx="0" ry="0" width="7" height="82" /> 
    <rect x="290" y="405" rx="0" ry="0" width="71" height="10" /> 
    <rect x="290" y="426" rx="0" ry="0" width="70" height="11" /> 
    <rect x="530" y="258" rx="0" ry="0" width="250" height="136" /> 
    <rect x="530" y="393" rx="0" ry="0" width="8" height="82" /> 
    <rect x="530" y="464" rx="0" ry="0" width="250" height="11" /> 
    <rect x="773" y="392" rx="0" ry="0" width="7" height="82" /> 
    <rect x="550" y="404" rx="0" ry="0" width="71" height="10" /> 
    <rect x="550" y="425" rx="0" ry="0" width="70" height="11" />
  </ContentLoader>
)

export default PublicVideoLoader;