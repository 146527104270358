import React, { Component } from "react";
class ObsHelp extends Component {
  render() {
    return (
      <div className="main">
        <div className="left-spacing1 staticpage">
          <div className="Spacer-10"></div>
          <div className="Spacer-10"></div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3>OBS Configuration</h3>
                  </div>
                  <div className="card-body obs-help">
                    <p>Follow the below steps for the obs live streaming.</p>

                    <ul>
                      <li>
                        1. Open the{" "}
                        <a
                          href="https://obsproject.com/"
                          className="text-primary"
                        >
                          OBS
                        </a>{" "}
                        software. Don't have OBS software?{" "}
                        <a
                          href="https://obsproject.com/download"
                          className="text-primary"
                        >
                          Click Here for Download{" "}
                        </a>
                      </li>
                      <li>2. {"Go to settings -> Open Stream Option"}</li>
                      <li>
                        3. Update the Stream URL and Key in the stream section
                      </li>
                      <li>
                        4. After the obs stream configurations, Click Start
                        Streaming for the broadcast
                      </li>
                    </ul>
                    <img
                      className="img img-responsive obs-help-img mt-5"
                      src={
                        window.location.origin + "/assets/images/obs-help-1.png"
                      }
                    ></img>

                    <h4 className="text-muted">
                      OBS Live streaming Tunning.
                      <a
                        target="_blank"
                        className="text-primary mt-5"
                        href="https://paper.dropbox.com/doc/OBS-Live-Stream-Tuning--BRfCMvZvAA8MTPY1si7Pb9VzAQ-49g4efUwdRJzsVWLjXi9f"
                      >
                        Click Here{" "}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Spacer-10"></div>
        </div>
      </div>
    );
  }
}

export default ObsHelp;
