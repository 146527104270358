import React from "react"
import ContentLoader from "react-content-loader"

const HomeLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1200}
    height={1200}
    viewBox="0 0 1200 1200"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="12" y="544" rx="0" ry="0" width="770" height="8" /> 
    <rect x="12" y="26" rx="0" ry="0" width="770" height="352" /> 
    <rect x="12" y="363" rx="0" ry="0" width="11" height="187" /> 
    <rect x="771" y="377" rx="0" ry="0" width="11" height="172" /> 
    <rect x="34" y="403" rx="0" ry="0" width="92" height="12" /> 
    <rect x="32" y="426" rx="0" ry="0" width="125" height="10" /> 
    <circle cx="60" cy="486" r="23" /> 
    <rect x="93" y="477" rx="0" ry="0" width="89" height="12" /> 
    <rect x="270" y="435" rx="0" ry="0" width="122" height="11" /> 
    <rect x="12" y="1095" rx="0" ry="0" width="770" height="8" /> 
    <rect x="12" y="577" rx="0" ry="0" width="770" height="352" /> 
    <rect x="12" y="914" rx="0" ry="0" width="11" height="187" /> 
    <rect x="771" y="928" rx="0" ry="0" width="11" height="172" /> 
    <rect x="34" y="954" rx="0" ry="0" width="92" height="12" /> 
    <rect x="32" y="977" rx="0" ry="0" width="125" height="10" /> 
    <circle cx="60" cy="1037" r="23" /> 
    <rect x="93" y="1028" rx="0" ry="0" width="89" height="12" /> 
    <rect x="270" y="986" rx="0" ry="0" width="122" height="11" />
  </ContentLoader>
)

export default HomeLoader;
