import React, { Component } from "react";
import { translate, t } from "react-multi-lang";

class FollowerCard extends Component {
  state = {};
  render() {
    const {
      follower,
      unFollowUser,
      followUser,
      followButtonDisable,
      followButtonLoadingContent,
      followInputData,
      unfollowButtonDisable,
      unfollowButtonLoadingContent,
      unfollowInputData,
    } = this.props;
    return (
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 top-margin">
        <div className="live-video-box">
          <a href="#">
            <div
              className="followers-img"
              style={{ backgroundImage: `url(${follower.picture})` }}
            ></div>
          </a>
          <div className="user-profile spacing">
            <h4 className="h4-s user-name text-bold overflow">
              {follower.name}
            </h4>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h4 className="h4-s user-name blue-clr">
                  <i className="fa fa-eye icon"></i>
                  {follower.total_followers} {t("followers")}
                </h4>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                {follower.show_follow == 1 ? (
                  <button
                    className=" btn-default btn-follow right-align left"
                    type="button"
                    disabled={
                      followInputData.user_id == follower.user_id &&
                      followButtonDisable == true
                        ? true
                        : false
                    }
                    onClick={(event) => followUser(event, follower)}
                  >
                    <i className="fa fa-user-plus icon"></i>
                    {followInputData.user_id == follower.user_id &&
                    followButtonLoadingContent != null
                      ? followButtonLoadingContent
                      : t("follow")}
                  </button>
                ) : null}
                {follower.show_unfollow == 1 ? (
                  <button
                    className=" btn-default btn-follow right-align left"
                    type="button"
                    disabled={
                      unfollowInputData.user_id == follower.user_id &&
                      unfollowButtonDisable == true
                        ? true
                        : false
                    }
                    onClick={(event) => unFollowUser(event, follower)}
                  >
                    <i className="fa fa-user-plus icon"></i>{" "}
                    {unfollowInputData.user_id == follower.user_id &&
                    unfollowButtonLoadingContent != null
                      ? unfollowButtonLoadingContent
                      : t("unfollow")}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default translate(FollowerCard);
