import React, { Component } from "react";

export default class VideoTag extends Component {
  componentDidMount() {
    let videox = document.querySelector("#localVideo");

    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          console.log("stream", stream);
          videox.srcObject = stream;
        })
        .catch(function (err0r) {
          console.log("Something went wrong!");
        });
    }
    // this.props.saveWebRTC();

    // const video = this.videoRef.current;
    // const constraints = { video: true }
    // navigator.mediaDevices.getUserMedia(constraints).then(
    //   (stream) => { video.srcObject = stream })
    // this.props.callWebRTC();
  }
  render() {
    return (
      <div>
        {/* <video autoPlay={true} ref={this.videoRef} /> */}
        <video
          id="localVideo"
          autoPlay
          muted
          controls
          playsInline
          style={{ width: "100%" }}
        ></video>
      </div>
    );
  }
}
