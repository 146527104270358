import React, { Component } from "react";
import BroadcastChat from "./BroadcastChat";
import Sidebar from "../layouts/sidebar/Sidebar";
import BroadcastPublicVideoSec from "./BroadcastPublicVideoSec";
import BroadcastStartModel from "./BroadcastStartModel";
import api from "../../Environment";
import ToastContent from "../helper/ToastContent";
import { withToastManager } from "react-toast-notifications";
import io from "socket.io-client";
import config from "react-global-configuration";
import { Redirect } from "react-router-dom";
import { translate, t } from "react-multi-lang";
import ObsUrlModel from "./ObsUrlModel";

const $ = window.$;
let chatSocket;

class LiveStreamingIndex extends Component {
  state = {
    subscribers: [],
    chatSocket: false,
    customizeData: {
      audioMute: true,
      audioUnmute: false,
      videoMute: true,
      videoUnmute: false,
      recordStatus: false,
    },
    snapshot: null,
    startBroadcastInputData: {},
    modelLoadingContent: null,
    modelButtonDisabled: false,
    ObsmodelLoadingContent: null,
    ObsmodelButtonDisabled: false,
    ObsStreaming: false,
    groupData: null,
    loadingGroup: true,
    liveVideoData: null,
    loadingLiveVideo: true,
    liveVideoSuggesstion: null,
    loadingLiveVideoSuggesstion: true,
    chatData: null,
    loadingChatData: true,
    chatInputMessage: "",
    loadMoreButtonDisable: false,
    loadingContent: null,
    skipCount: 0,
    viewerCount: 0,
    streamingStatus: false,
    viewerProfileRedirect: false,
    streamingType: null,
    mainStreamer: false,
    loadingStreamingPage: true,
    apiCallstatus: false,
    startBroadcastButtonDisable: false,
    startBroadcastButtonLoadingContent: null,
  };

  componentDidMount() {
    this.setState({ loadingStreamingPage: false });

    this.getGroupDetails();
    // this.getSingleLiveVideoDetails();
    // this.getLiveVideoSuggesstionDetails();
  }

  modelChange = ({ currentTarget: input }) => {
    const startBroadcastInputData = {
      ...this.state.startBroadcastInputData,
    };
    startBroadcastInputData[input.name] = input.value;
    if (input.name == "payment_status" && input.value == 1) {
      $("#broadcast-amount").show();
    }
    if (input.name == "payment_status" && input.value == 0) {
      $("#broadcast-amount").hide();
    }
    this.setState({ startBroadcastInputData });
  };

  getGroupDetails = () => {
    api.postMethod("live_groups_index").then((response) => {
      if (response.data.success) {
        this.setState({
          groupData: response.data.data.groups,
          loadingGroup: false,
        });
      } else {
        ToastContent(
          this.props.toastManager,
          response.data.error_messages,
          "error"
        );
      }
    });
  };

  startBroadcastAPI = (event) => {
    event.preventDefault();
    this.setState({
      modelLoadingContent: t("loading"),
      modelButtonDisabled: true,
      startBroadcastButtonDisable: true,
      startBroadcastButtonLoadingContent: t("loading"),
    });
    this.startBroadcastAPICall();
  };

  startBroadcastAPICall = () => {
    api
      .postMethod(
        "live_videos_broadcast_start",
        this.state.startBroadcastInputData
      )
      .then((response) => {
        if (response.data.success) {
          this.setState({
            loadingLiveVideo: false,
            liveVideoData: response.data.data,
          });
          ToastContent(
            this.props.toastManager,
            response.data.message,
            "success"
          );
          // Start streaming code should be entered here.

          if (response.data.data.livestream_type == "obs") {
            this.setState({
              ObsStreaming: true,
            });
            $("#obs_url").click();
            $("#start_broadcast").modal("hide");
            return false;
          } else {
            this.startStreaming();
          }
          this.chatSocketConnect();
        } else {
          if (response.data.error_code == 132) {
            ToastContent(this.props.toastManager, response.data.error, "error");
            // Send to subscription page.
          } else if (response.data.error_code == 174) {
            if (window.confirm(response.data.error)) {
              this.eraseOldStreamingAPI();
            } else {
              this.setState({
                modelLoadingContent: null,
                modelButtonDisabled: false,
                startBroadcastButtonDisable: false,
                startBroadcastButtonLoadingContent: null,
              });
              return false;
            }
          } else {
            ToastContent(this.props.toastManager, response.data.error, "error");
            this.setState({
              modelLoadingContent: null,
              modelButtonDisabled: false,
              startBroadcastButtonDisable: false,
              startBroadcastButtonLoadingContent: null,
            });
            return false;
          }
        }
        this.setState({
          modelLoadingContent: null,
          modelButtonDisabled: false,
        });
        $("#start_broadcast").modal("hide");
      });
  };

  snapShotAPI = () => {
    const inputData = {
      snapshot: this.state.snapshot,
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    api.postMethod("live_videos_snapshot_save", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          snapshot: null,
        });
      } else {
      }
    });
  };

  stopBroadcastAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    if (this.state.apiCallstatus == false)
      api
        .postMethod("live_videos_broadcast_stop", inputData)
        .then((response) => {
          if (response.data.success) {
            ToastContent(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({ apiCallstatus: true });
          } else {
            ToastContent(this.props.toastManager, response.data.error, "error");
          }
        });
  };

  eraseOldStreamingAPI = () => {
    api.postMethod("live_videos_erase_old_streamings").then((response) => {
      if (response.data.success) {
        ToastContent(this.props.toastManager, response.data.message, "success");
        this.startBroadcastAPICall();
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
      }
    });
  };

  viewerUpdateAPI = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
    };
    api.postMethod("live_videos_viewer_update", inputData).then((response) => {
      if (response.data.success) {
        // ToastContent(
        //     this.props.toastManager,
        //     response.data.message,
        //     "success"
        // );
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        if (
          response.data.error_code == 169 ||
          response.data.error_code == 170 ||
          response.data.error_code == 171
        ) {
          this.props.history.push("/");
        }
      }
    });
  };

  getLiveVideoSuggesstionDetails = () => {
    api.postMethod("live_videos_suggestions").then((response) => {
      if (response.data.success) {
        this.setState({
          liveVideoSuggesstion: response.data.data,
          loadingLiveVideoSuggesstion: false,
        });
      } else {
      }
    });
  };

  getSingleLiveVideoDetails = () => {
    const inputData = {
      live_video_id: this.props.location.state.live_video_id,
    };
    api.postMethod("live_videos_view", inputData).then((response) => {
      if (response.data.success) {
        this.setState({
          liveVideoData: response.data.data,
          loadingLiveVideo: false,
        });
        setTimeout(() => {
          if (this.state.liveVideoData.is_user_needs_to_pay == 1) {
            // this.props.history.push("/invoice", {
            //   state: this.state.liveVideoData,
            //   prevPath: location.pathname,
            // });
            this.props.history.push("/invoice", this.state.liveVideoData);
          } else {
            // Watch streaming code needs to be add here.
            this.chatSocketConnect();
          }
        }, 2000);
      } else {
        ToastContent(this.props.toastManager, response.data.error, "error");
        this.props.history.push("/");
      }
    });
  };

  getLiveVideoChatDetails = () => {
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };
    this.getLiveVideoChatAPI(inputData);
  };

  loadMore = (event) => {
    event.preventDefault();
    this.setState({
      loadMoreButtonDisable: true,
      loadingContent: t("loading"),
    });
    const inputData = {
      live_video_id: this.state.liveVideoData.live_video_id,
      skip: this.state.skipCount,
    };

    this.getLiveVideoChatAPI(inputData);
  };

  getLiveVideoChatAPI = (inputData) => {
    let items;
    api.postMethod("live_videos_chat", inputData).then((response) => {
      if (response.data.success) {
        if (this.state.chatData != null) {
          items = [...this.state.chatData, ...response.data.data];
        } else {
          items = [...response.data.data];
        }
        items = items.reverse();
        this.setState({
          chatData: items,
          loadingChatData: false,
          skipCount: response.data.data.length + this.state.skipCount,
          loadMoreButtonDisable: false,
          loadingContent: null,
        });
      } else {
      }
    });
  };

  chatSocketConnect = () => {
    // check the socket url is configured
    let chatSocketUrl = config.get("configData.chat_socket_url");
    console.log("chatSocketUrl" + chatSocketUrl);
    if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
      chatSocket = io(chatSocketUrl, {
        query: `room: '` + this.state.liveVideoData.virtual_id + `'`,
      });

      chatSocket.emit("update sender", {
        room: this.state.liveVideoData.virtual_id,
      });
      let chatContent;
      chatSocket.on("message", (newData) => {
        let content = [];
        content.push(newData);
        chatContent = [...this.state.chatData, ...content];
        this.setState({ chatData: chatContent });
      });
    }
  };

  handleChatSubmit = (event) => {
    event.preventDefault();
    if(this.state.chatInputMessage) {
      const type =
        this.state.liveVideoData.user_id == localStorage.getItem("userId")
          ? "uv"
          : "vu";
      let chatData = [
        {
          live_video_id: this.state.liveVideoData.live_video_id,
          user_id: this.state.liveVideoData.user_id,
          live_video_viewer_id: localStorage.getItem("userId"),
          message: this.state.chatInputMessage,
          type: type,
          user_name: localStorage.getItem("username"),
          user_picture: localStorage.getItem("user_picture"),
        },
      ];
      let chatSocketUrl = config.get("configData.chat_socket_url");
      console.log("chatSocketUrl" + chatSocketUrl);
      if (chatSocketUrl && Object.keys(this.state.liveVideoData).length > 0) {
        chatSocket.emit("message", chatData[0]);
      }
      let messages;
      if (this.state.chatData != null) {
        messages = [...this.state.chatData, ...chatData];
      } else {
        messages = [...chatData];
      }
      this.setState({
        chatData: messages,
        chatInputMessage: "",
      });
    }
  };

  chatInputChange = ({ currentTarget: input }) => {
    this.setState({ chatInputMessage: input.value });
  };

  startLiveAPI = (event, live_video_Data) => {
    event.preventDefault();
    this.setState({
      ObsmodelLoadingContent: null,
      ObsmodelButtonDisabled: false,
      startBroadcastButtonDisable: true,
      startBroadcastButtonLoadingContent: "Loading...",
    });
    if (live_video_Data.livestream_type == "obs") {
      window.location = "/livehost/" + live_video_Data.stream_key;
    } else {
      this.startLiveAPICall(live_video_Data);
    }
  };

  render() {
    const {
      startBroadcastInputData,
      modelLoadingContent,
      modelButtonDisabled,
      loadingGroup,
      groupData,
      ObsmodelLoadingContent,
      ObsmodelButtonDisabled,
      loadingContent,
      loadMoreButtonDisable,
      chatData,
      loadingChatData,
    } = this.state;
    return (
      <div className="main">
        <Sidebar />

        {this.state.loadingStreamingPage ? (
          <div className="no-data-found-img">
            <div className="Spacer-10"></div>
            <img
              src={
                window.location.origin +
                "/assets/img/fidget-spinner-loading.gif"
              }
            ></img>
          </div>
        ) : (
          // "Getting details... Please wait.."
          <div className="sec-padding left-spacing1">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 top-margin">
                {/* {this.state.streamingStatus == true &&
                this.state.subscribers.length == 0 ? (
                  <Redirect to="/" />
                ) : null} */}
                <div className="relative">
                  {/* Live streaming view code here */}
                </div>
                <div className="relative">
                  <img
                    src="/assets/img/stream.jpg"
                    className="img-responsive broadcast-img"
                  />
                  <div className="broadcast-btn">
                    <button
                      className="btn"
                      type="button"
                      // onClick={this.startStreaming}
                      data-toggle="modal"
                      data-target="#start_broadcast"
                      disabled={this.state.startBroadcastButtonDisable}
                    >
                      {this.state.startBroadcastButtonLoadingContent != null
                        ? this.state.startBroadcastButtonLoadingContent
                        : t("start_broadcasting")}
                    </button>
                  </div>
                  {this.state.ObsStreaming == true ? (
                    <button
                      className="btn none"
                      type="button"
                      data-toggle="modal"
                      data-target="#obs_url_modal"
                      id="obs_url"
                    >
                      URL STREAMING
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <BroadcastChat
                loadMore={this.loadMore}
                chatData={chatData}
                loadingChatData={loadingChatData}
                loadingContent={loadingContent}
                loadMoreButtonDisable={loadMoreButtonDisable}
                chatInputMessage={this.state.chatInputMessage}
                chatInputChange={this.chatInputChange}
                handleChatSubmit={this.handleChatSubmit}
              />
            </div>
          </div>
        )}
        {/* <BroadcastPublicVideoSec
          liveVideoSuggesstion={liveVideoSuggesstion}
          loadingLiveVideoSuggesstion={loadingLiveVideoSuggesstion}
        /> */}
        <BroadcastStartModel
          startBroadcastInputData={startBroadcastInputData}
          modelLoadingContent={modelLoadingContent}
          modelButtonDisabled={modelButtonDisabled}
          groupData={groupData}
          loadingGroup={loadingGroup}
          modelChange={this.modelChange}
          startBroadcastAPI={this.startBroadcastAPI}
        />
        <ObsUrlModel
          liveVideoData={this.state.liveVideoData}
          loadingLiveVideo={this.state.loadingLiveVideo}
          ObsmodelButtonDisabled={ObsmodelButtonDisabled}
          ObsmodelLoadingContent={ObsmodelLoadingContent}
          startLiveAPI={this.startLiveAPI}
        />
      </div>
    );
  }
}

export default withToastManager(translate(LiveStreamingIndex));
