import React from "react"
import ContentLoader from "react-content-loader"

const CardDeatilsLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={1010}
    height={550}
    viewBox="0 0 1200 650"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="31" y="130" rx="0" ry="0" width="0" height="9" /> 
    <rect x="7" y="23" rx="0" ry="0" width="138" height="79" /> 
    <rect x="158" y="45" rx="0" ry="0" width="183" height="12" /> 
    <rect x="159" y="71" rx="0" ry="0" width="143" height="9" /> 
    <rect x="548" y="54" rx="0" ry="0" width="26" height="14" /> 
    <rect x="4" y="120" rx="0" ry="0" width="576" height="5" /> 
    <rect x="8" y="144" rx="0" ry="0" width="138" height="79" /> 
    <rect x="159" y="166" rx="0" ry="0" width="183" height="12" /> 
    <rect x="160" y="192" rx="0" ry="0" width="143" height="9" /> 
    <rect x="549" y="175" rx="0" ry="0" width="26" height="14" /> 
    <rect x="5" y="241" rx="0" ry="0" width="576" height="5" /> 
    <rect x="8" y="266" rx="0" ry="0" width="138" height="79" /> 
    <rect x="159" y="288" rx="0" ry="0" width="183" height="12" /> 
    <rect x="160" y="314" rx="0" ry="0" width="143" height="9" /> 
    <rect x="549" y="297" rx="0" ry="0" width="26" height="14" /> 
    <rect x="5" y="363" rx="0" ry="0" width="576" height="5" /> 
    <rect x="9" y="387" rx="0" ry="0" width="138" height="79" /> 
    <rect x="160" y="409" rx="0" ry="0" width="183" height="12" /> 
    <rect x="161" y="435" rx="0" ry="0" width="143" height="9" /> 
    <rect x="550" y="418" rx="0" ry="0" width="26" height="14" /> 
    <rect x="6" y="484" rx="0" ry="0" width="576" height="5" /> 
    <rect x="9" y="505" rx="0" ry="0" width="138" height="79" /> 
    <rect x="161" y="538" rx="0" ry="0" width="143" height="9" /> 
    <rect x="8" y="599" rx="0" ry="0" width="576" height="5" />
  </ContentLoader>
)

export default CardDeatilsLoader;